"use strict";

export function initInScope ($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }
    $scope.find('.js-toggle-offscreen').on('click', function (evt) {
        evt.preventDefault();

        let $this = $(this);
        let $target = $($this.attr('href')) || $($this.data('target'));

        if(!$target.hasClass('is-open')) {
            $target.addClass('is-open');
        } else {
            $target.removeClass('is-open');
        }
    });
}